.main-slider {
    position: absolute;
    top: '0px';
    width: 100%; 
    height: 70vh!important;
    z-index: -2;
    opacity: 0.15;
    // .dev {
    //     width: 30%;
    //     max-width: 400px;
    //     position: absolute;
    //     right: 15%;
    //     top: 80px;
    // }
    // .dev-details {
    //     position: absolute;
    //     left: 15%;
    //     top: 100px;
    //     h1 {
    //         font-size: 3.5rem;
    //         color: white;
    //     }
    //     .highlight-text {
    //         color: violet;
    //     }
    //     h2 {
    //         display: inline-flex;
    //         font-size: 2.5rem;
    //         margin: 0px;
    //     }
    // }
    // .btn-box {
    //     margin-top: 50px;
    // }
}
