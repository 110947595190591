.nav {
    position: fixed;
    top: 0;
    display: flex;
    width: 100%;
    padding: 20px;
    column-gap: 0px;
    justify-content: space-around;
    height: 30px;
    z-index: 999;
    transition: ease all 0.5s;
    // a {
    //     color: black;
    // }
    .menu-bar {
        display: flex;
        column-gap: 0px;
        justify-content: space-evenly;
        flex-grow: 0.3;
    }
}

.nav__black {
    -webkit-box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.75);
}
/* .nav__transparent{
    background-color:transparent
} */
.light-navbar-bg {
    background-color: rgb(40, 92, 175);
}
.dark-navbar-bg {
    background-color: #0d0d0d!important;
}

.ayub {
    display: flex;
    div:first-child {
        padding: 0px 10px;
        margin-left: -24px;
    }
}

.logo-title {
    img {
        width: 200px;
        position: absolute;
        top: 7px;
    }
}

@media only screen and (max-width: 900px) {
    .logo-title {
        img {
            width: 150px;
            position: absolute;
            top: 10px;
        }
    }
}
