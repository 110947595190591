.img-wrapper {
    overflow: hidden;
    img {
        transition: all ease 1s;
    }
    img:hover {
        transform: scale(1.25);
        transition: all ease 1s;
    }
}
.footer {
    padding: 8px;
    .details {
        text-align: center;
        p {
            padding: 8px;
        }
    }
}
@media screen and (min-width: 900px) {
    .footer {
        padding: 0px;
        .details {
            text-align: left;
            p {
                padding: 24px;
            }
        }
        .details:last-child {
            text-align: right;
        }
    }
}
