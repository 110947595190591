.contact svg{
    padding-right:15px;
}
@media only screen and (max-width :426px) {
    .contact-details{
        padding:8px 0px!important
    }
    .contact{
        padding: 20px 5px!important;
        h4{
            font-size:32px;
        }
        h5{
            font-size:20px;
        }
    }
    
}