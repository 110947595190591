@import url("https://fonts.googleapis.com/css2?family=New+Tegomin&display=swap");
img {
	border-radius: 3px;
}

.hero {
	font-family: "New Tegomin", serif !important;
	color: #fff;
	background: linear-gradient(271deg, #a0e9ff 30%, #a162e8 50%, #f093b0 70%, #edca85 94%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
